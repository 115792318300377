<template>
  <div>
    <div class="title flexB" style="display: block;">
      <h1>{{ $t("top-select-user-manager-member-log") }}</h1>
<!--      <div>
        &lt;!&ndash;        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >&ndash;&gt;
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
      </div>-->
    </div>
    <div class="contents userMgt">
      <div class="box one filter" style="display: flex;flex-direction: row;flex-wrap: wrap;">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <option value="name">{{ $t("user-data-name") }}</option>
            <option value="loginId">{{ $t("user-data-email-id") }}</option>
            <option value="departmentName">{{ $t("user-data-dept-name") }}</option>
            <option value="mobileNumber">{{ $t("user-data-phone-num") }}</option>
          </select>
          <input
              type="text"
              v-model="keyword"
              @keydown.enter="search"
          />
        </div>
        <div class="flex">
          <p class="bold" style="margin-left: 10px;">{{ $t("user-data-login-date") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="flex">
          <p class="bold" style="margin-left: 10px;">{{ $t("user-data-log-service") }}</p>
          <label>
            <input
                type="radio"
                name="service"
                checked
                value=""
                v-model="connectionDevice"
            />{{ $t("search-keyword-all") }}
          </label>
          <label>
            <input
                type="radio"
                name="service"
                value="ControllerApp"
                v-model="connectionDevice"
            />{{ $t("user-data-log-controller-app") }}
          </label>
          <label>
            <input
                type="radio"
                name="service"
                value="ViewApp"
                v-model="connectionDevice"
            />{{ $t("user-data-log-viewer-app") }}
          </label>
          <label>
            <input
                type="radio"
                name="service"
                value="Web"
                v-model="connectionDevice"
            />{{ $t("user-data-log-manager-web") }}
          </label>
        </div>
        <div class="flexC" style="margin-top: 10px;">
          <p class="bold" style="margin-left: 0px;">{{ $t("user-data-log-user-type") }}</p>
          <label>
            <input
                type="radio"
                name="user"
                checked
                v-model="userType"
                value=""
            />{{ $t("search-keyword-all") }}
          </label>
          <label v-if="isAdmin">
            <input
                type="radio"
                name="user"
                v-model="userType"
                value="Admin"
            />{{ $t("user-data-type-admin") }}
          </label>
          <label>
            <input
                type="radio"
                name="user"
                v-model="userType"
                value="Manager"
            />{{ $t("user-data-type-manager") }}
          </label>
          <label>
            <input
                type="radio"
                name="user"
                v-model="userType"
                value="User"
            />{{ $t("user-data-type-user") }}
          </label>
        </div>
        <div class="buttonWrap" style="width:100%; display: flex; margin-top: 15px;">
          <button class="point medium" @click="search" style="margin-left: 0px; width: 120px;">
            {{ $t("btn-searching") }}
          </button>
          <button class="medium margin6" @click="reset" style="width: 120px;"> {{ $t("btn-reset") }}</button>
        </div>
      </div>
      <div class="box one">
        <h2 class="bold">
          {{ $t("data-total") }}
          <span class="blue">{{ total }}</span
          >{{ $t("data-case-ea") }}
        </h2>
        <table>
          <tr>
            <th>No</th>
            <th>{{ $t("user-data-log-service") }}</th>
            <th>{{ $t("user-data-log-user-type") }}</th>
            <th>{{ $t("user-data-name") }}</th>
            <th>{{ $t("user-data-email-id") }}</th>
            <th>{{ $t("user-data-dept-name") }}</th>
            <th>{{ $t("user-data-phone-num") }}</th>
            <th>{{ $t("user-data-login-date") }}</th>
          </tr>
          <colgroup>
            <col style="width: 10%;"/>
            <col style="width: 12%;"/>
            <col style="width: 13%;"/>
            <col style="width: 10%;"/>
            <col style="width: 20%;"/>
            <col style="width: 10%;"/>
            <col style="width: 12%;"/>
            <col style="width: 13%;"/>
<!--            <col style="width: 10%;"/>-->
          </colgroup>
          <tr v-if="total == 0">
            <td colspan="9" style="text-align: center;">
              {{ $t("search-not-found") }}
            </td>
          </tr>
          <tr v-for="(data, i) in workerList" :key="i">
            <td>{{ total - (currentPage * size + i) }}</td>
            <td>
              {{
                data.device == "ViewApp"
                    ? $t("user-data-log-viewer-app")
                    : data.device == "Web"
                    ? $t("user-data-log-manager-web")
                    : data.device == "ControllerApp"
                        ? $t("user-data-log-controller-app")
                        : "-"
              }}
            </td>
            <td>
              {{
                data.userType == "User"
                    ? $t("user-data-type-user")
                    : data.userType == "Admin"
                    ? $t("user-data-type-admin")
                          : ""
              }}
            </td>
            <td>{{ data.name }}</td>
            <td>{{ data.loginId }}</td>
            <td>{{ data.deptInfoName }}</td>
            <td>{{ getMask(data.mobileNumber) }}</td>
            <td>{{ moment(data.loginDate).format("YYYY.MM.DD HH:mm") }}</td>
            <!-- <td>
              {{
                data.lassRequestDate
                    ? moment(data.lassRequestDate).format("YYYY.MM.DD HH:mm")
                    : "-"
              }}
            </td> -->
          </tr>
        </table>
        <div class="pagination">
          <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              :page-size="size"
              @current-change="handleCurrentChange"
              :current-page="currentPage + 1"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.title{margin-top: 0;}
</style>
<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { fetchWorkerList } from "@/api/index";
import { format } from "@/mixins/format";
import {mapState} from "vuex";
export default {
  mixins: [format],
  components: { Datepicker },
  name: "Home",
  data() {
    return {
      ko: ko,
      moment: moment,
      startYmd: null,
      endYmd: null,
      currentPage: 0,
      total: 0,
      size: 10,
      keywordType: "all",
      userType: "",
      keyword: "",
      connectionDevice: "",
      workerList: [],
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 6, sub: 2 });
  },
  mounted() {
    this.callFunctionsWhenMounted();
  },
  computed: {
    ...mapState({
      isAdmin: state => state.store.isAdmin,
    })
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getWorkerList();
    },
    search(){
      if(this.startYmd != null || this.endYmd != null) {
        if (this.startYmd == null || this.endYmd == null || this.startYmd > this.endYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.currentPage = 0;
      this.getWorkerList();
    },
    getWorkerList() {
      var sendUserType = this.userType;

      if(!this.isAdmin){
        if(sendUserType == null || sendUserType == ""){
          sendUserType = 'Manager,User';
        }
      }

      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        keywordType: this.keywordType,
        keyword: this.keyword,
        userTypes: sendUserType,
        ConnectionDevice: this.connectionDevice,
      };

      params["registerDate.from"] = null;
      params["registerDate.to"] = null;
      if(this.startYmd != null && this.endYmd != null) {
        params["registerDate.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["registerDate.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }
      fetchWorkerList(params).then((res) => {
        this.total = res.data.data.total;
        this.workerList = res.data.data.content;
      });
    },
    handleRefresh() {
      this.reset();
      this.callFunctionsWhenMounted();
    },
    reset(){
      this.currentPage = 0;
      this.keywordType = "all";
      this.keyword = "";
      this.userType = "";
      this.connectionDevice = "";
      this.startYmd = null;
      this.endYmd = null;
    },
    callFunctionsWhenMounted(){
      this.getWorkerList();
    }
  },
};
</script>
